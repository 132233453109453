import React, { useState, useEffect } from 'react';
import { ConsultantSelectorStyle, ConsultantSelectorTitleDiv, ConsultantSelectorRow, ButtonRow, ButtonSubmitStyle } from './style'
import { Text, ConsultantSelectorItem, LoadingSpin } from '../../../components'
import { useSelector } from 'react-redux';
import { localizedStrings } from './../../../constants/localizedStrings';
const width = window.innerWidth;
const isMobile = width < 860;

const consultants = [
    { name: "Gustavo", value: 1, email: "gustavo.nogueira@contele.com.br"  },
    { name: "Thiago", value: 2, email: "thiago.andrade@contele.com.br" },
    { name: "Sheila Barros", value: 3,  email: "sheila.barros@contele.com.br"   },
    { name: "Daniel", value: 4,  email: "daniel.garcia@contele.com.br"   },
    { name: "Luana", value: 5,  email: "luana.mello@contele.com.br"   },
    
];

export default function ConsultantSelector({ register, selectedPlan, setValue }) {
    const [selectedConsultant, setSelectedConsultant] = useState({})

    const {
        createLoading
    } = useSelector(state => state.subscription);

    const {
        company
    } = useSelector(state => state.users);

    const getDefaultConsultant = () => {
        let index = 0
        const consultant = consultants.find((consultant, i) => {
            if(consultant.email === company.consultant) {
                index = i
                return true;
            } 
            return false
        }) || consultants[0];
        return {
            consultant,
            index
        };
    }

    const onSelectConsultant = ({ consultant, index }) => {
        if (consultant?.name === selectedConsultant.name) return;
        setSelectedConsultant({ ...consultant, index })
        setValue("consultant", { ...consultant, index })
    }

    useEffect(() => {
        onSelectConsultant(getDefaultConsultant());
    }, [])

    return (
        <ConsultantSelectorStyle className="row" isMobile={isMobile}>
            <ConsultantSelectorTitleDiv className="col-md-12 col-sm-12 col-12" isMobile={isMobile}>
                {
                }
                <Text fontSize={"15px"} color={"#767676"}>
                    {localizedStrings.selectConsultant}
                </Text>
            </ConsultantSelectorTitleDiv>
            <ConsultantSelectorRow className="col-md-12 col-sm-12 col-12 row" isMobile={isMobile}>
                {
                    consultants.map((consultant, index) =>
                        <ConsultantSelectorItem {...consultant} key={index} index={index}
                            isSelected={selectedConsultant.name === consultant.name}
                            onClickItem={() => onSelectConsultant({consultant, index})} register={register}
                        />
                    )
                }
            </ConsultantSelectorRow>
            <ButtonRow className="col-md-12 col-sm-12 col-12" isMobile={isMobile}>

                <ButtonSubmitStyle type={"submit"} >
                    <LoadingSpin
                        show={createLoading}
                        position={"absolute"}
                        left={"10px"}
                        weight={"4px"}
                        halfColored
                        backColor={"#66ac59"}
                        frontColor={"#ffffff"}
                        size={"20px"}
                    />
                    <Text color="#fff" fontSize={"18px"} fontFamily={"Effra light, Roboto,sans-serif"} textTransform={"uppercase"}>
                        {
                            selectedPlan?.type === localizedStrings.bankSlip ?
                                localizedStrings.generateTicket :
                                localizedStrings.startSubscription
                        }
                    </Text>
                </ButtonSubmitStyle>
                <div>
                    <Text textAlign={"justify"} color="#767676" fontSize={"15px"} fontFamily={"Effra light, Roboto,sans-serif"} >
                        {localizedStrings.confirmPaymentDescription1}
                        <Text as={"a"} target="_blank" href="https://blog.contelege.com.br/termo-de-uso-software-de-gestao-de-equipe-externa/">
                            {localizedStrings.confirmPaymentDescription2}
                        </Text>
                    </Text>
                </div>
                <div>
                    <Text textAlign={"justify"} color="#767676" fontSize={"15px"} fontFamily={"Effra light, Roboto,sans-serif"} >
                        {localizedStrings.confirmPaymentDescription3}
                        <Text as={"a"} target="_blank" href="https://vindi.com.br/">
                            {localizedStrings.confirmPaymentDescription4}
                        </Text>
                    </Text>
                </div>
            </ButtonRow>
        </ConsultantSelectorStyle>
    );
}
